import React,{useEffect, useState} from 'react';
import logo from '../assets/netplus/logo-final.jpeg';
import {Link} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {toast} from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import config from '../globals'


const login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        document.title = "Login - Netplus College";
      }, [])
    let navigate = useNavigate();

    useEffect(() => {
        const loggedInUser = localStorage.getItem("token");
        const userId = localStorage.getItem("user_public");
        if (loggedInUser && userId) {
            navigate("/crm/dashboard");
            return;
        }
    }, []);

    const handleSubmit = async e => {
        const btn = document.getElementById("btn-submit");
        btn.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Login`;
        btn.disabled = true;
        e.preventDefault();
        await loginUser({
          email,
          password
        });
    }

    async function loginUser(credentials) {
        await axios
          .post(`${config.globals.backend_url_api}/users/signin`, credentials, {"Contant-Type": "application/json"})
          .then((response) => {
                if(response.status === 200){
    
                    toast.success(response.data.message);


                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('user_public', response.data.user);
                    setTimeout(() => {
                        navigate("/crm", { replace: true });
                    }, 2000);
                    return response.json();
    
                    
                }else if(response.status === 401){  
                    toast.error(response.data.message);
                }
          }).catch((error) => {
            if (error.response) {
    
                toast.error(error.response.data.message);
                // console.log(error.response.data.message);
    
            }
          }).finally(() => {
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = `Login`;
            btn.disabled = false;
          })
      }
    
      
  return (
    
    <>
        <div className='mt-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 mx-auto mt-5'>
                    <div className='text-center'>
                        <img src={logo} className="img-fluid mb-4" alt='logo' width={200}/>
                    </div>
                        <div className='border mt-4 p-4 bg-light rounded'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group my-3'>
                                    <label htmlFor='email' className='pb-1'>Email address</label>
                                    <input type='email' className='form-control form-control-sm rounded-0' id='email' aria-describedby='emailHelp' placeholder='Enter email' required onChange={e=>setEmail(e.target.value)}/>
                                    </div>
                                <div className='form-group my-2'>
                                    <label htmlFor='password' className='pb-1'>Password</label>
                                    <input type='password' className='form-control form-control-sm rounded-0' id='password' placeholder='Password' required onChange={e=>setPassword(e.target.value)}/>
                                </div>
                                <div className='text-center'>
                                    <button className='btn btn-np-success btn-sm rounded-0' id="btn-submit"><Icon.BoxArrowInRight/> Login</button>
                                </div>
                                <div className='text-center pt-2'>
                                    <Link to='/crm/forgot-password' className='text-decoration-none text-dark'>forgot password?</Link> | <Link to="/" className='text-decoration-none text-dark'>visit website</Link>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default login
