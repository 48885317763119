import React,{useEffect, useState} from 'react';
import logo from '../assets/netplus/logo-final.jpeg';
import {Link} from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import config from '../globals'


const forgotPassword = () => {
    const [email, setEmail] = useState('');

    useEffect(() => {
        document.title = "Forgot Password - Netplus College";
    }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        // submit-btn disabled
        document.getElementById('submit-btn').disabled = true;
        await forgotPasswordUser({
            email
        });
    }
    let navigate = useNavigate();

    async function forgotPasswordUser(credentials) {
        await axios
            .post(`${config.globals.backend_url_api}/users/reset-password`, credentials, {"Contant-Type": "application/json"})
            .then((response) => {
                if(response.status === 200){
                    
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate("/crm/login", { replace: true });
                    }, 2000);
                    return response.json();
                }else if(response.status === 500){
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                    // console.log(error.response.data.message);
                }
            }
        );
        // enable submit-btn
        document.getElementById('submit-btn').disabled = false;

    }
  
  return (
    <>
      <div className='mt-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 mx-auto mt-5'>
                    <div className='text-center'>
                        <img src={logo} className="img-fluid mb-4" alt='logo' width={200}/>
                    </div>
                        <div className='border mt-4 p-4 bg-light rounded'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group py-3'>
                                    <label htmlFor='email' className='pb-1'>Please enter your registered email</label>
                                    <input type='email' className='form-control form-control-sm rounded-0' id='email' aria-describedby='emailHelp' placeholder='Enter email' onChange={e=>setEmail(e.target.value)} required/>
                                  </div>
                                
                                <div className='text-center '>
                                    <button className='btn btn-np-success btn-sm rounded-0' id="submit-btn"><Icon.SendCheck/> Send</button>
                                </div>
                                <div className='text-center pt-2'>
                                    <Link to='/crm/login' className='text-decoration-none text-dark'>Login</Link>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default forgotPassword