const host= window.location.hostname
if(host === 'localhost' || host === '127.0.0.1'){
    backend_url_api = 'http://localhost:4000/api'
    backend_url = 'http://localhost:4000'
}else{
    backend_url_api = 'https://api.netpluscollege.com/api'
    backend_url = 'https://api.netpluscollege.com'
}
// backend_url_api = 'https://api.netpluscollege.com/api'
// backend_url = 'https://api.netpluscollege.com'
module.exports = {
    globals: {
        'backend_url_api': backend_url_api,
        'backend_url': backend_url,
        'tiny_api_key': '3djck8249ppha7ajlw5u4i4sslhhbefzwbhbhys4v3fy1t1q',
        'email':'zakria.khan@hhdsoftware.org'

    }
};

