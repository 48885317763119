import React,{ useEffect, useRef, useState } from 'react'
import { Link } from "react-router-dom";
import logo from '../assets/netplus/logo-final.jpeg';
import * as Icon from 'react-bootstrap-icons';
// import { useState } from 'react';
import { useLocation } from "react-router-dom";
import NewOffers from './NewOffers';
import slug from 'slug';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from 'src/globals'
import "react-bootstrap-submenu/dist/index.css";


const NavMenu = (props) => {
    const baseURL = props.baseURL;
    const [searchQuery , setSearchQuery] = useState('');
    const location = useLocation();
    const [locationParam, setLocationParam] = useState([]);
    const [fullCourses, setFullCourses] = useState([]);
    const [fullCategories, setFullCategories] = useState([]);
    const [partCourses, setPartCourses] = useState([]);
    const [partCategories, setPartCategories] = useState([]);

    //destructuring pathname from location
    const { pathname } = location;

    useEffect(() => {
        setLocationParam([]);
        const locations = pathname.split('/');
        //loop through the locations and set the locationParam
        locations.forEach((location) => {
            if (location !== "") {
                setLocationParam((locationParam) => [...locationParam, location]);
            }
        })
    }, [pathname]);

    useEffect(() => {
        getCoursesMenu(1);
        getCoursesMenu(2);
    }, []);

    const getCoursesMenu = async (type) => {
        await axios.get(`${config.globals.backend_url_api}/courses/names?course_type=${type}`)
        .then((response) => {
            if(response.status === 200){
                if(type === 1){
                    setFullCourses(response.data.courses);
                    setFullCategories(response.data.course_categories);
                }else if(type === 2){
                    setPartCourses(response.data.courses);
                    setPartCategories(response.data.course_categories);
                }else{
                    setFullCourses('');
                    setFullCategories('');

                    setPartCourses('');
                    setPartCategories('');
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    let navigate = useNavigate();

    const handleParentClick = (event, path) => {
        // event.preventDefault();
        navigate(path, { replace: true });
    
    };


    const overlayRef = useRef();

    // This function is called when the "Start Searching" button gets clicked
    const openSearch = () => {
        overlayRef.current.style.width = '100%';
    };

    // This function is called when the "Close" button is clicked
    const closeSearch = () => {
        overlayRef.current.style.width = '0%';
    };

    const searchHandler = (e) => {
        e.preventDefault();
        navigate(`/search/keyword?query=${searchQuery}`, { replace: true });
    };



return (
    <>
        <div className='bg-np-primary'>
            <div className='container'>
                <div className='row text-white'>
                    <div className='col-lg-8 col-md-12 pt-4 pb-xl-2 pb-lg-4 pb-md-4 pb-sm-4 pb-4'>
                        <div className="flex-wrap d-flex align-items-center fs-np-14-mob">
                            <div id="contact-number">&nbsp;
                                <a href="tel:4162920003" className="text-decoration-none text-white">
                                <Icon.TelephoneFill size={14}/> +416-292-0003
                                </a>
                            </div>
                            &nbsp; &nbsp; &nbsp; | &nbsp;
                            <div id="email">
                                <a href="mailto:contact@netpluscollege.com" className="text-decoration-none text-white">
                                <Icon.EnvelopeFill size={14} className="ms-lg-3 ms-1"/>  contact@netpluscollege.com
                                </a>
                            </div>

                            <div className='d-xl-block d-none d-lg-none'>
                                &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp;
                                <Link to="/covid-19-vaccination-policy" className="text-decoration-none text-np-success" style={{fontWeight:"600"}}>
                                Vaccination Policy
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-md-4 text-md-center d-xl-block d-none d-lg-none'>
                        <div className="flex-wrap p-0 d-flex align-items-center justify-content-end">
                            {/* <div className="login">
                            <Link to="/crm/login" className="text-white text-decoration-none">
                                Student Login
                            </Link>
                            </div>
                            &nbsp; &nbsp; */}
                            <button className='btn btn-success rounded-0 text-work-sans py-3' onClick={props.handleShow}>
                                Apply Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Navbar bg="white" expand="xl" className="" id="website-nav">
        <Container>
            <Navbar.Brand><Link
            className="flex-wrap navbar-brand d-flex align-items-baseline"
            to="/"
            >
            <img
                src={logo}
                alt="Netplus Logo"
                className="py-2 img-fluid"
                style={{height: "60px"}}
            />
            </Link></Navbar.Brand>
            <span className='pe-xl-2 fs-np-16 mx-auto me-1 text-work-sans d-xl-none d-lg-block' style={{fontWeight:"500", cursor:"pointer"}} onClick={openSearch}>
                <Icon.Search size={18} className="fw-bolder text-work-sans" style={{fontWeight:"500"}}/>
            </span>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="end"
            >
            <Offcanvas.Header closeButton>

                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    <img
                        src={logo}
                        alt="Netplus Logo"
                        className="py-2 img-fluid"
                        style={{height: "60px"}}
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 text-work-sans website-np">
                
                    {/* <Link to="/about-us" className={splitLocation[1] === "about-us" ? "active pe-xl-2 nav-link fs-np-16 text-work-sans" : "pe-xl-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>About Us</Link> */}
                    <NavDropdown renderMenuOnMount={true} onToggle={e=>(handleParentClick(e,"/about-us"))}
                        className={splitLocation[1] === "about-us" || splitLocation[1] === "professors" ? "active pe-xl-2 fs-np-16 nav-link pt-0 text-work-sans sub-nav-menu" : "pe-xl-2 fs-np-16 nav-link pt-0 text-work-sans sub-nav-menu"} style={{fontWeight:"500"}}
                        title="About Us"
                        id={`offcanvasNavbarDropdown-expand-lg`}
                    >
                        {/* <Link to="/about-us" className={splitLocation[1] === "about-us" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>About Us</Link> */}
                        <Link to="/professors" className={splitLocation[1] === "professors" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Professors</Link>
                        <Link to="/contact" className={splitLocation[1] === "contact" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Contact Us</Link>
                        
                    </NavDropdown>
                    
                    {/* <Link to="/" className='pe-xl-2 fs-np-16 nav-link'>Program & Courses</Link> */}
                    <NavDropdown 
                        renderMenuOnMount={true}
                        className={splitLocation[1] === "full-time-courses" || splitLocation[1] === "part-time-courses" ? "active pe-xl-2 fs-np-16 nav-link pt-0 text-work-sans sub-nav-menu mt-0" : "pe-xl-2 fs-np-16 nav-link pt-0 text-work-sans sub-nav-menu mt-0"} style={{fontWeight:"500"}}
                        title="Program & Courses"
                        id={`offcanvasNavbarDropdown-expand-lg`}
                    >
                        <DropdownSubmenu onToggle={e=>(handleParentClick(e, "/full-time-courses"))} title="Full Time" className='sub-child-menu' id={`offcanvasNavbarDropdown-expand-lg`}>
                            {/* <NavDropdown.Item href="#action/8.1">Sub 1</NavDropdown.Item> */}
                            {fullCategories?.map((category, index) => (
                                <Link to={`/full-time-courses/${category.slug}`} className={splitLocation[2] === category.slug ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"400"}} key={index} >{category.name}</Link>
                            ))}
                            {fullCourses?.map((course, index) => (
                                <Link to={`/full-time/${course.slug}`} className={splitLocation[2] === course.slug ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"400"}} key={index}>{course.course_name}</Link>
                            ))}

                            {/* <DropdownSubmenu href="#action/3.7" title="Course 1" renderMenuOnMount={false}>
                                <NavDropdown.Item href="#action/9.1">Sub 2</NavDropdown.Item>
                            </DropdownSubmenu> */}
                        </DropdownSubmenu>
                        <DropdownSubmenu onToggle={e=>(handleParentClick(e, "/part-time-courses"))} title="Part Time" renderMenuOnMount={false} className='sub-child-menu dropdown-submenu'>
                            {partCategories?.map((category, index) => (
                                <Link to={`/part-time-courses/${category.slug}`} className={splitLocation[2] === category.slug ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"400"}} key={index}>{category.name}</Link>
                            ))}
                            {partCourses?.map((course, index) => (
                                <Link to={`/course/${course.slug}`} className={splitLocation[2] === course.slug ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"400"}} key={index}>{course.course_name}</Link>
                            ))}
                        </DropdownSubmenu>
                                
                        {/* <NavDropdown.Item href="#action/8.1">Part Time Courses</NavDropdown.Item> */}
                        
                        {/* <Link to="/full-time-courses" className={splitLocation[1] === "full-time-courses" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Full Time Courses</Link> */}
                        {/* <Link to="/part-time-courses" className={splitLocation[1] === "part-time-courses" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Part Time Courses</Link> */}
                        
                    </NavDropdown>
                    <Link to="/financial-aid" className={splitLocation[1] === "financial-aid" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Financial Aid</Link>
                    <Link to="/international-students" className={splitLocation[1] === "international-students" ? "active pe-xl-2 fs-np-16 nav-link text-work-sans" : "pe-xl-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>International Students</Link>
                    <Link to="/calendar" className={splitLocation[1] === "calendar" ? "active pe-xl-2 fs-np-16 nav-link text-work-sans" : "pe-xl-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Course Calendar</Link>
                    <div className='d-flex d-xl-none'>
                        <Link to="/covid-19-vaccination-policy" className={splitLocation[1] === "covid-19-vaccination-policy" ? "active pe-xl-2 fs-np-16 nav-link text-work-sans" : "pe-xl-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Vaccination Policy</Link>
                    </div>

                    {/* <Link to="/" className='pe-xl-2 fs-np-16 nav-link text-work-sans' style={{fontWeight:"500"}}>Student Login</Link> */}
                    <span className='pe-xl-2 fs-np-16 text-work-sans ms-lg-4 d-xl-block d-none mt-2' style={{fontWeight:"500"}} onClick={openSearch}>
                        <Icon.Search size={18} className="fw-bolder text-work-sans" style={{fontWeight:"500", cursor:"pointer"}}/>
                    </span>

                    <hr />
                    {/* <Link to="/" className='pe-xl-2 fs-np-16 nav-link text-work-sans d-xl-none w-100 btn btn-success rounded-0 text-white mb-2' style={{fontWeight:"500"}}>Student Login</Link> */}
                    <button className='pe-xl-2 fs-np-16 nav-link text-work-sans d-xl-none w-100 btn btn-success rounded-0 text-white' style={{fontWeight:"500"}} onClick={props.handleShow}>Apply Now</button>
                    <div className="flex-wrap d-flex d-xl-none mx-auto mt-3">
                        <div className="social-links pt-2">
                            <a href="https://www.facebook.com/NetPLUSITCollege/" className="text-decoration-none text-np-dark" target="_black" >
                            <Icon.Facebook size={26} className="mx-2 home-social-link"/>
                            </a>
                            {/* instagram */}
                            <a href="https://www.instagram.com/netpluscollege/" className="text-decoration-none text-np-dark" target="_black" >
                            <Icon.Instagram size={26} className="mx-2 home-social-link"/>
                            </a>
                            {/* twitter */}
                            {/* <a href="https://twitter.com/netpluscollege" className="text-decoration-none text-np-dark" target="_black" >
                            <Icon.Twitter size={26} className="mx-2 home-social-link"/>
                            </a> */}
                            {/* linkedin */}
                            <a href="https://www.linkedin.com/company/netplus-college-of-information-technology/" className="text-decoration-none text-np-dark" target="_black" >
                            <Icon.Linkedin size={26} className="mx-2 home-social-link"/>
                            </a>
                            {/* youtube */}
                            <a href="https://www.youtube.com/channel/UCNnzrFgdgCDADrQTJ9yMd7w" className="text-decoration-none text-np-dark" target="_black" >
                            <Icon.Youtube size={26} className="mx-2 home-social-link"/>
                            </a>
                            
                        </div>
                    </div>
                    {/* <Icon.Search size={14} className="mt-2" />
                    {/* <NavDropdown
                        title="Dropdown"
                        id={`offcanvasNavbarDropdown-expand-lg`}
                    >
                        <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action4">
                        Another action
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action5">
                        Something else here
                        </NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
                {/* <Form className="d-none">
                <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                />
                <Button variant="outline-success">Search</Button>
                </Form> */}
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Container>
        </Navbar>
        <div className='bg-light-blue'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 col-12' id="new_offers">
                        {/* if page is / then show */}
                        {splitLocation[1] === "" ? (
                            <div className="text-work-sans py-2 fs-6 my-0"><NewOffers /></div>
                        ) : (
                            <div className="text-work-sans py-2 fs-6 my-0">
                                {/* loop throgh locationParam and add it to breadcrum */}
                                <Link to="/" className="text-decoration-none text-np-dark">
                                    <span className="text-np-dark">Home</span>
                                    <span className="mx-2">»</span>
                                </Link>
                                {locationParam.map((item, index) => {
                                    // add previous item to link
                                    let link = "";
                                    for (let i = 0; i <= index; i++) {
                                        // if locationParam[1] is course then dont add it to link
                                        // console.log(locationParam[0]);

                                        if (locationParam[0] === "course" || locationParam[0] === "full-time" || locationParam[0] === "search" || locationParam[0] === 'course-search') {
                                            link  = "";
                                        } else {
                                        link += locationParam[i] + "/";
                                        }
                                    }

                                    // console.log(item);
                                    return (
                                    <span key={index}>
                                        
                                        <Link  to={"/" + link} className="text-decoration-none text-np-dark">
                                            {/* capitalize fiest letter */}
                                            {/*  replace -  */}
                                            <span className="text-np-dark">{item.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</span>

                                            {/* {item.charAt(0).toUpperCase() + item.slice(1)} */}
                                        </Link>
                                        {index !== locationParam.length - 1 ? (
                                            <span className="mx-2">»</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </span>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                    <div className='col-md-6 text-end'>    
                        <div className='mx-auto me-2'>
                            {/* social links */}
                            <div className="flex-wrap d-flex d-xl-block d-none d-lg-none">
                                <div className="social-links py-2">
                                <a href="https://www.facebook.com/NetPLUSITCollege" target="_black" className="text-decoration-none text-np-dark">
                                    <Icon.Facebook size={28} className="mx-2 home-social-link"/>
                                </a>
                                {/* instagram */}
                                <a href="https://www.instagram.com/netpluscollege/" target="_blank" className="text-decoration-none text-np-dark">
                                    <Icon.Instagram size={28} className="mx-2 home-social-link"/>
                                </a>
                                {/* twitter */}
                                {/* <a href="https://twitter.com/netpluscollege" target="_blank" className="text-decoration-none text-np-dark">
                                    <Icon.Twitter size={26} className="mx-2 home-social-link"/>
                                </a> */}
                                {/* linkedin */}
                                <a href="https://www.linkedin.com/company/netplus-college-of-information-technology/" target="_blank" className="text-decoration-none text-np-dark">
                                    <Icon.Linkedin size={28} className="mx-2 home-social-link"/>
                                </a>
                                {/* youtube */}
                                <a href="https://www.youtube.com/channel/UCNnzrFgdgCDADrQTJ9yMd7w" target="_blank" className="text-decoration-none text-np-dark">
                                    <Icon.Youtube size={28} className="mx-2 home-social-link"/>
                                </a>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>

        </div>
        {/* The search overlay */}
        <div ref={overlayRef} className='overlay' id="search-overlay">
            <button className='close-button' onClick={closeSearch}>
            &times;
            </button>
            <div className='overlay-content'>
                <div className='row px-3'>
                    <div className='col-md-6 col-sm-12 mx-auto'>
                        <div className="input-group">
                            <input className="form-control border-end-0 border rounded-pill" type="text" placeholder='Search...' id="example-search-input" value={searchQuery} onChange={e=>setSearchQuery(e.target.value)}/>
                            <span className="input-group-append ms-1">
                                <button className="btn bg-np-success text-white rounded-pill ms-n3" type="button" onClick={searchHandler}>
                                    <Icon.Search/>
                                </button>
                            </span>
                        </div>
                        <hr className='text-black mt-5'/>
                        <div className='mt-4 text-black text-start py-4 px-4 rounded' id="page-section-title" style={{backgroundColor: "#fff"}}>
                            
                            <div className='row' style={{fontWeight:500}}>
                                <div className='col-md-6 col-12'>
                                    <div className='fs-np-18' style={{fontWeight:600}}>
                                        Quick Links
                                        <hr className="border-np-success2 mt-2 w50px"></hr>
                                    </div>
                                    <div className='fs-np-14'>
                                        <ul className='list-style-disc'>
                                            <li className='mb-2'>
                                                <Link to='/about-us' className='text-black text-decoration-none'>About Us</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/full-time-courses' className='text-black text-decoration-none'>Full-time Courses</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/part-time-courses' className='text-black text-decoration-none'>Part-time Courses</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/contact' className='text-black text-decoration-none'>Contact Us</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/better-jobs-ontario' className='text-black text-decoration-none'>Better Jobs Ontario</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 mt-md-5 mt-0'>
                                    {/* <div className='fs-np-18 invisible' style={{fontWeight:600}}>
                                        Quick Links
                                        <hr className="border-np-success2 mt-2 w50px"></hr>
                                    </div> */}
                                    <div className='fs-np-14'>
                                        <ul className='list-style-disc'>
                                            <li className='mb-2'>
                                                <Link to='/professors' className='text-black text-decoration-none'>Professors</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/financial-aid' className='text-black text-decoration-none'>Financial Aid</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/policies' className='text-black text-decoration-none'>Policies</Link>
                                            </li>
                                            <li className='mb-2'>
                                                <Link to='/cojg' className='text-black text-decoration-none'>COJG</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
            
            </div>
        </div>
    </>
)
}

export default NavMenu