import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import * as Icon from 'react-bootstrap-icons';




const NewOffers = () => {
    const settings = {
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        arrows : false,
        fade: true,
      };

  return (
    <>
        <Slider {...settings} className="text-start">

            <div className="slick-slide" key="1">
                NetPLUS college offers Microsoft courses
            </div> 
            <div className="slick-slide" key="1">
                Microsoft 365 Security Administration
            </div>
            <div className="slick-slide" key="1">
                Microsoft Teams | Microsoft Azure
            </div>
            

        </Slider>
    
    </>
  )
}

export default NewOffers