import React from 'react'
import * as Icon from 'react-bootstrap-icons'


export const LeadTypes = [
    {
        id: 1,
        title: "New Lead",
        bg: "bg-info opacity-10", 
    },
    {
        id: 2,
        title: "Hot Lead",
        bg: "bg-info opacity-10",
    },
    {
        id: 3,
        title: "Warm Lead",
        bg: "bg-info opacity-10",

    },
    {
        id: 4,
        title: "Cold Lead",
        bg: "bg-danger opacity-10", 

    },
    {
        id: 5,
        title: "Prospective student",
        bg: "bg-success opacity-10",
    },
    {
        id: 6,
        title: "Current Student",
        bg: "bg-success opacity-10",
    },
    {
        id: 7,
        title: "Past Student",
        bg: "bg-success opacity-10",
    },
    {
        id: 8,
        title: "Other",
        bg: "bg-info opacity-10",
    }

]

export const CurrentStatus = [
    {
        id: 1,
        title: "Active",
        bg: "bg-np-success"
    },
    {
        id: 2,
        title: "Inactive",
        bg: "bg-danger"
    },
    // {
    //     id: 3,
    //     title: "Pending",
    //     bg: "bg-warning"
    // }
]

export const Durations = [
    {
        id: 5,
        title: "Hour",
        title_p: "Hours"
    },
    {
        id: 1,
        title: "Day",
        title_p: "Days"
    },
    {
        id: 2,
        title: "Week",
        title_p: "Weeks"    
    },
    {
        id: 3,
        title: "Month",
        title_p: "Months"
    },
    {
        id: 4,
        title: "Year",
        title_p: "Years"
    }

]

export const CourseTypes = [
    {
        id: 1,
        title: "Full Time"
    },
    {
        id: 2,
        title: "Part Time"
    },
    // {
    //     id: 3,
    //     title: "Others"
    // }
]


// activity with props
export const activity = [
    {
        id: 1,
        title: "Call",
        icon: <Icon.Telephone/>
    },
    {
        id: 2,
        title: "Email",
        icon: <Icon.Envelope/>
    },
    {
        id: 3,
        title: "Meeting",
        icon: <Icon.Calendar/>
    },
    {
        id: 4,
        title: "Task",
        icon: <Icon.ListCheck/>
    },
    // {
    //     id: 5,
    //     title: "Deadline",
    //     icon: <Icon.ClockFill/>

    // },
    // {
    //     id: 6,
    //     title: "Lunch",
    //     icon: <Icon.CupFill/>
    // }


]

export const activity_days_filter = [
    {
        id: 10,
        title: "Overdue",
        add : -1
    },
    {
        id: 3,
        title: "Yesterday",
        add : -1
    },
    {
        id: 1,
        title: "Today",
        add : 0
    },
    {
        id: 2,
        title: "Tomorrow",
        add : 1
    },
    {
        id: 4,
        title: "This Week",
        add : 7
    },
    // next week
    {
        id: 5,
        title: "Next Week",
        add : 14
    },
    {
        id: 6,
        title: "This Month",
        add : 30
    }
]


export const activity_days_filter_log = [
    {
        id: 3,
        title: "Yesterday",
        add : -1
    },
    {
        id: 1,
        title: "Today",
        add : 0
    },
    {
        id: 4,
        title: "This Week",
        add : 7
    },
    // next week
    {
        id: 5,
        title: "Next Week",
        add : 14
    },
    {
        id: 6,
        title: "This Month",
        add : 30
    }
]

export const Campus= [
    {
        id: 1,
        title: "On Campus"
    },
    {
        id: 2,
        title: "Online"
    }
]
