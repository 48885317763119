import React from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import WebsiteRouter from './website/Router';
import CRMRouter from './crm/Router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.css';
import config from './globals';
import page404 from './website/404'

const App = () => {
  //setBaseURL 


//   const LeadModal = () => {
//     return (
//       <Modal show={show} onHide={handleClose} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title className='text-np-success fw-bold fs-np-24'>Student Inquiry Form</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//             <Form className='px-md-5' onSubmit={handleSubmit}>
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                     <Form.Control
//                         type="text"
//                         placeholder="First Name *"
//                         autoFocus
//                         className='rounded-0 form-control-sm'
//                         onChange={(e) => setFirstName(e.target.value)} value={first_name}
//                     />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                     <Form.Control
//                         type="text"
//                         placeholder="Last Name *"
//                         className='rounded-0 form-control-sm'
//                         onChange={(e) => setLastName(e.target.value)} value={last_name}
//                     />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
//                     <Form.Control
//                         type="text"
//                         placeholder="Phone *"
//                         className='rounded-0 form-control-sm'
//                         onChange={(e) => setPhone(e.target.value)} value={phone}
//                     />
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
//                     <Form.Control
//                         type="email"
//                         placeholder="Email *"
//                         className='rounded-0 form-control-sm'
//                         onChange={(e) => setEmail(e.target.value)} value={email}
                
//                     />
//                 </Form.Group>
//                 {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
//                     <Form.Control
//                         as="textarea"
//                         placeholder="Address *"
//                         className='rounded-0 form-control-sm'
//                         onChange={(e) => setAddress(e.target.value)} value={address}
//                         height={{height: '100px'}}
//                     />
//                 </Form.Group> */}
//                 {/* radio input for location */}
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
//                     <Form.Label className='fs-np-14'>Apply As *</Form.Label>
//                     <div className='d-flex'>
//                         <Form.Check
//                             type="radio"
//                             label="Local Student"
//                             name="formHorizontalRadios"
//                             id="formHorizontalRadios1"
//                             className='fs-np-14 pe-3'
//                             onChange={(e) => setLocation(true)} value={location}
//                             defaultChecked

//                         />
//                         <Form.Check
//                             type="radio"
//                             label="International Student"
//                             name="formHorizontalRadios"
//                             id="formHorizontalRadios2"
//                             className='fs-np-14'
//                             onChange={(e) => setLocation(false)} value={location}

//                         />
//                     </div>
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
//                     {/* select with option */}
//                     <Form.Control
//                         as="select"
//                         className='rounded-0 form-select form-select-sm'
//                         onChange={(e) => changeCourseType(e)} value={courseType}
//                     >
//                         <option value="">Select Course Type</option>
//                         {CourseTypes?.map((type, index) => (
//                             <option key={index} value={type.id}>{type.title}</option>
//                         ))}
//                     </Form.Control>
                    
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
//                     <Form.Control
//                         as="select"
//                         className='rounded-0 form-select form-select-sm'
//                         onChange={(e) => setCourse(e.target.value)} value={course}
//                     >
//                         <option value="">Select Course</option>
//                         <option value="000000000000000000000000">Need advise with program choice</option>
//                         {courses?.map((course, index) => (
//                             <option key={index} value={course._id}>{course.course_name}</option>
//                         ))}
//                     </Form.Control>
                    
//                 </Form.Group>
//                 { location === 1 ? (
//                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
//                     <Form.Label className='fs-np-14'>Are you looking for financial aid help?</Form.Label>
//                     <div className='d-flex'>
//                         <Form.Check
//                             type="radio"
//                             label="No"
//                             name="financialaid"
//                             id="financialaid1"
//                             className='fs-np-14 pe-3'
//                             onChange={(e) => setFinancialSelect(true)} value={financialSelect}
//                             defaultChecked
//                         />
//                         <Form.Check
//                             type="radio"
//                             label="Yes"
//                             name="financialaid"
//                             id="financialaid2"
//                             className='fs-np-14'
//                             onChange={(e) => setFinancialSelect(false)} value={financialSelect}
//                         />
//                     </div>

//                     {/* <Form.Control
//                         as="select"
//                         className='rounded-0 form-select form-select-sm'
//                         onChange={(e) => setCourse(e.target.value)} value={course}
//                     >
//                         <option value="">Select</option> */}
//                         {/* <option value="000000000000000000000000">Need advise with program choice</option> */}
//                         {/* {courses?.map((course, index) => (
//                             <option key={index} value={course._id}>{course.course_name}</option>
//                         ))} */}
//                         {/* <option value="1">Option 1</option>
//                         <option value="2">Option 2</option>
//                     </Form.Control> */}
                    
//                 </Form.Group>
//                 ) : (
//                     ""
//                 )}


//                 {/* <input type="hidden" name="course_id" value={source} /> */}
                
//             </Form>
//             </Modal.Body>
//             <Modal.Footer>
//                 <div className='mx-auto'>
//                     <Button className='btn btn-secondary rounded-0' variant="default" onClick={handleClose}>
//                         Close
//                     </Button>
//                      &nbsp;
//                     <Button className='btn btn-np-success rounded-0' variant="default" onClick={handleSubmit} id="btn-submit">
//                         Submit
//                     </Button>
//                 </div>
//             </Modal.Footer>
//     </Modal>
//     )

// }

  // const [baseURL, setBaseURL] = React.useState('http://localhost:4000/api');
  const baseURL = config.globals.backend_url_api;

  return (
    <React.StrictMode>
        <Router>
          <CRMRouter/>
          <WebsiteRouter baseURL={baseURL}/>
          <Routes>
            <Route path="/*" element={<page404/>}  />

          </Routes>
          
        </Router>
    </React.StrictMode>
  )
}

export default App