import React,{useEffect, useState} from 'react';
import logo from '../assets/netplus/logo-final.jpeg';
import {Link, useParams } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import {toast} from 'react-toastify';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import config from '../globals'


const PasswordReset = () => {
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const { userId, token } = useParams();
    useEffect(() => {
        document.title = "Reset Password - Netplus College";
      }, [])

    const handleSubmit = async e => {
        e.preventDefault();
        // submit-btn disabled
        document.getElementById('submit-btn').disabled = true;
        //check if password and confirm password are same
        if(password === cPassword){

            await resetPasswordUser({
                password,
                cPassword,
                userId,
                token
            });
        }else{
            toast.error("Password and Confirm Password are not same");
            document.getElementById('submit-btn').disabled = false;

        }
    }
    let navigate = useNavigate();

    async function resetPasswordUser(credentials) {
        await axios
            .post(`${config.globals.backend_url_api}/users/reset-password/`+userId+"/"+token, credentials, {"Contant-Type": "application/json"})
            .then((response) => {
                if(response.status === 200){
                    toast.success(response.data.message);
                    setTimeout(() => {
                        navigate("/crm/login", { replace: true });
                    }, 2000);
                    return response.json();
                }else if(response.status === 400){
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                    // console.log(error.response.data.message);
                }
            }
        );
        // enable submit-btn
        document.getElementById('submit-btn').disabled = false;

    }


  return (
    <>
        <div className='mt-5'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 mx-auto mt-5'>
                    <div className='text-center'>
                        <img src={logo} className="img-fluid mb-4" alt='logo' width={200}/>
                    </div>
                        <div className='border mt-4 p-4 bg-light rounded'>
                            <form onSubmit={handleSubmit}>
                                <div className='form-group my-3'>
                                    <label htmlFor='password' className='pb-1'>Password</label>
                                    <input type='password' className='form-control form-control-sm rounded-0' id='password' aria-describedby='passwordHelp' placeholder='Enter password' required onChange={e=>setPassword(e.target.value)}/>
                                    </div>
                                <div className='form-group my-2'>
                                    <label htmlFor='c_password' className='pb-1'>Confirm Password</label>
                                    <input type='password' className='form-control form-control-sm rounded-0' id='c_password' placeholder='Confirm password' required onChange={e=>setCPassword(e.target.value)}/>
                                </div>
                                <input type="hidden" name="user_id" value={userId} id="user_id" />
                                <input type="hidden" name="token" value={token} id="token" />
                                <div className='text-center'>
                                    <button className='btn btn-np-success btn-sm rounded-0' id="submit-btn"><Icon.BoxArrowInRight/> Reset</button>
                                </div>
                                <div className='text-center pt-2'>
                                    <Link to='/crm/login' className='text-decoration-none text-dark'>Login?</Link> | <Link to="/" className='text-decoration-none text-dark'>visit website</Link>
                                </div>
                            </form>
                        </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default PasswordReset